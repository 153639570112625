

import { mapState, mapGetters, mapActions } from "vuex";
import Card from "primevue/card";
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import LocalDesktopService from '@/services/LocalDesktopService';
import NotificationCard from "@/components/Notification-Card.vue";
const localDesktopService = new LocalDesktopService();

interface PrinterListResponse {
  printerlist: string[]; 
}

export default defineComponent({
  name: "Settings",
  components: {
    Card,
    InputText,
    InputSwitch,
    Button,
    CollapsibleSection,
    Dropdown,
    NotificationCard,
  },


  computed: {
    ...mapState(["session", "scan", "pos"]),
    ...mapGetters({
      getUser: "session/getUser",
      getFloatingSidebar: "sidemenu/getFloatingSidebar",
      getWaiveConvenienceFeeByDefault: "control/getWaiveConvenienceFeeByDefault",
      getAutoAddParts: "pos/getAutoAddParts",
      getRegisterPrinter: "pos/getRegisterPrinter",
      getDefaultPickTicketPrinter: "pos/getDefaultPickTicketPrinter",
      getAutoLoadDefaultCustomers: "pos/getAutoLoadDefaultCustomers",
      getLinks: "sidemenu/getLinks",
      getDefaultLandingPage: "sidemenu/getDefaultLandingPage",
      getWaiveConvenienceFeeUsers: "control/getWaiveConvenienceFeeUsers",
      getACHFee: "control/getACHFee",
      getCCFee: "control/getCCFee",
      hideLoadCustomerButton: "mrkControl/hideLoadCustomerButton",
      getPrinterQueues: "printControl/getPrinterQueues",
      showPickTicketPrint: "mrkControl/showPickTicketPrint",
      getClient: "session/getClient",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
      getHidePosCustomLineItems: "pos/getHidePosCustomLineItems",
    }),
  },
  

  
   async mounted() {
    const scan = this.scan;
    this.suffix = scan.suffix;
    this.prefix = scan.prefix;
    this.scanMode = scan.scanMode;
    this.floatingSidebar = this.getFloatingSidebar;    
    this.fetchCCControl();  

     localDesktopService.fetchPrinterList().then((response: any) => {
     const typedResponse = response as PrinterListResponse;
     this.printers = typedResponse.printerlist;     
     this.registerPrinter = this.getRegisterPrinter;
     this.defaultPickTicketPrinter = this.getDefaultPickTicketPrinter;
     

});

  },
  methods: {
    ...mapActions({
      setScanSuffix: "scan/setScanSuffix",
      setScanPrefix: "scan/setScanPrefix",
      setScanMode: "scan/setScanMode",
      setFloatingSidebar: "sidemenu/setFloatingSidebar",
      setWaiveConvenienceFeeByDefault: "control/setWaiveConvenienceFeeByDefault",
      toggleWaiveConvenienceFeeByDefault: "control/toggleWaiveConvenienceFeeByDefault",
      toggleAutoAddParts: "pos/toggleAutoAddParts",
      setRegisterPrinter: "pos/setRegisterPrinter",
      setDefaultPickTicketPrinter: "pos/setDefaultPickTicketPrinter",
      toggleAutoLoadDefaultCustomers: "pos/toggleAutoLoadDefaultCustomers",
      setDefaultLandingPage: "sidemenu/setDefaultLandingPage",
      fetchControls: "control/fetchControl",
      getPrinterControl: "printControl/getPrintControl",
      getMrkControl: "mrkControl/getMrkControl",
      addNotification: "notification/add",
      setHidePosCustomLineItems: "pos/setHidePosCustomLineItems",
    }),
    
    async fetchCCControl() {
      await this.fetchControls({
      Client: "",
      id: "CC", 
      procedure: "CC.CONTROL",
      filename: "CONTROL",      
      }).finally(() => {
        this.allowWaiveConvenienceFee = this.getWaiveConvenienceFeeUsers?.includes(this.getUser?.user_id);
        this.waiveConvenienceFee = this.getWaiveConvenienceFeeByDefault;    
        this.isCCDataLoaded = true;
      })

    },
    saveRegisterOptions() {
      this.saveRegisterSettings = true;
      this.setRegisterPrinter(this.registerPrinter);
      this.addNotification({
            type: "success",
            message: "Settings saved.",
          });
    },
    saveScanOptions() {
      this.saveScanSettings = true;
      this.setScanSuffix(this.suffix);
      this.setScanPrefix(this.prefix);
      this.setScanMode(this.scanMode);
      this.addNotification({
            type: "success",
            message: "Settings saved.",
          });
    },
    savePOSOptions() {
      this.savePOSSettings = true;
      this.setDefaultPickTicketPrinter(this.defaultPickTicketPrinter);
      this.addNotification({
            type: "success",
            message: "Settings saved.",
          });
    },
    savePreferenceOptions() {
      this.savePreferenceSettings = true;
      this.setFloatingSidebar(this.floatingSidebar);
      this.setDefaultLandingPage(JSON.stringify(this.defaultLandingPage));
      this.addNotification({
            type: "success",
            message: "Settings saved.",
          });
    },
    toggleSectionIcon(tab: "userInfo" | "accountInfo" | "roverScan" | "pos" | "preferences" | "registerInfo") {
      this.sectionStatus[tab] = !this.sectionStatus[tab];
    },
    
  },
  data() {
    return {
      defaultLandingPage: null,
      suffix: "",
      prefix: "",
      scanMode: false,
      saveScanSettings: false,
      savePOSSettings: false,
      saveRegisterSettings: false,
      floatingSidebar: false,
      waiveConvenienceFee: false,
      savePreferenceSettings: false,
      sectionStatus: {
        userInfo: true,
        accountInfo: true,
        roverScan: true,
        pos: true,
        preferences: true,
        registerInfo: true,
      },
      isCCDataLoaded: false,
      allowWaiveConvenienceFee: false,
      registerPrinter: "",
      printers: [] as string[],
      defaultPickTicketPrinter: "",
      pickTicketPrinters: [] as string[],      

      
    };
  },
  
  async created() {
    this.defaultLandingPage = JSON.parse(this.getDefaultLandingPage);
    this.getMrkControl({client: this.getClient, selectedCode: this.getSelectedCoCode, refresh: true})
    await this.getPrinterControl({client: this.getClient, refresh: true})
  },
});
